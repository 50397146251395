<template>
  <div class="container dashboard">
    <div class="page-title">
      <h2>Create App</h2>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="submit">
          <div class="alert alert-info mb-4">
            Create an application to start interacting with our powerful sevices. This is typically the name of your organization or product or other unique identifier.
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>App Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="App Name"
                  required
                  v-model="app.name"
                />
              </div>
            </div>
          </div>
          <div class="form-group mt-4">
            <label
              >Add a Description
              <small class="text-muted">*optional</small></label
            >
            <textarea
              rows="6"
              class="form-control"
              v-model="app.description"
              placeholder="Describe your app"
            ></textarea>
          </div>
          <div class="form-group mt-5">
            <label
              >Add an avatar for this app
              <small class="text-muted">*optional</small></label
            >
            <input type="file" class="form-control" ref="avatar" />
          </div>
          <div class="form-group text-right mt-5">
            <button class="btn btn-primary"><i class="lni-save mr-2"></i> Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      app: {},
    };
  },

  methods: {
    submit() {
      this.$loader.start()
      this.$axios.post('/api/v1/apps', this.app).then(response => {
        this.$loader.stop()
        this.$router.push({ name: 'app.overview', params: { id: response.data.id }})
      })
    }
  }
};
</script>
